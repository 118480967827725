import React, { useEffect, useState } from 'react';
import "./style.css";
import 'react-toastify/dist/ReactToastify.css';

//images
import tikBox from "../../Assets/Images/tikIcon.png";
import callIcom from "../../Assets/Images/callIcon.png";
import whatsapIcom from "../../Assets/Images/whatsappIcon.png";
import homeGrayBg from "../../Assets/Images/homeBackDrop.png";
import homeBgImgMobile from "../../Assets/Images/homeBackgRoundMobile.png";
import homeBgImgTabe from "../../Assets/Images/homeBackgRoundTab.png";
import homeBgImg from "../../Assets/Images/homeBackgRound.png";
import cl2Logo from "../../Assets/Images/bookMyC2.png";
import bCard1 from "../../Assets/Images/BookingCardIcon1.png";
import bCard2 from "../../Assets/Images/BookingCardIcon2.png";
import bCard3 from "../../Assets/Images/BookingCardIcon3.png";
import graphImg1 from "../../Assets/Images/graphImg1.png";
import graphImg2 from "../../Assets/Images/graphImg2.png";
import graphImg3 from "../../Assets/Images/graphImg3.png";
import num1 from "../../Assets/Images/num1.png";
import num2 from "../../Assets/Images/num2.png";
import num3 from "../../Assets/Images/num3.png";
import experienceIcon from "../../Assets/Images/experienceIcon.png";
import qualifiedIcon from "../../Assets/Images/qualifiedIcon.png";
import trekingIcon from "../../Assets/Images/trekkingIcon.png";
import Rline from "../../Assets/Images/redDotLine.png";
import Wline from "../../Assets/Images/whiteDotLine.png";
import redBlur from "../../Assets/Images/redBlur.png";
import locationTrak1 from "../../Assets/Images/locationTrack1.png"
import locationTrak2 from "../../Assets/Images/locationrak.png"
import tentBg from "../../Assets/Images/TentBg.png";
import mounten1Bg from "../../Assets/Images/mountenBg.png";
import mounten2Bg from "../../Assets/Images/mounten2Bg.png";
import mounten3Bg from "../../Assets/Images/mounten3Bg.png";
import begPackBg from "../../Assets/Images/begPakBg.png";
import homeBlurBg from "../../Assets/Images/homeBlarBg.png";


//components
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material'
import NavBar from '../../Components/NavBar'
import Footer from '../../Components/Footer'
import OTPpop from "../../Components/OTPpop"
import ChangeNumPop from "../../Components/ChangeNumPop"
import ThankPop from "../../Components/ThankPop"
import { ToastContainer } from 'react-toastify';

export default function Home({ langDropVal, setLanDropVal }) {
  const [t, i18n] = useTranslation("global");
  const [otpPop, setOtpPop] = useState(false);
  const [changeNumPop, setChangeNumPop] = useState(false);
  const [thankPop, setThankPop] = useState(false);
  const [homeBtn, setHomeBtm] = useState(0);
  const [num, setNum] = useState();
  const OTPValidTime = 30;
  const [expTime, setExpTime] = useState(OTPValidTime);


  const handelChangeLanguage = (lang) => {
    setLanDropVal(lang)
    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    if (otpPop || changeNumPop || thankPop) {
      setTimeout(() => {
        document.body.style.overflow = 'hidden';
      }, 150)
    } else {
      document.body.style.overflow = '';
    }
    if (langDropVal === "English") {
      setHomeBtm(0)
    }
    if (langDropVal === "Hindi") {
      setHomeBtm(1)
    }
  })


  return (
    <>
      <Box className="HomeContainer">
        <ToastContainer />
        <NavBar langDropVal={langDropVal} setLanDropVal={setLanDropVal} />
        <OTPpop otpPop={otpPop} setOtpPop={setOtpPop} setChangeNumPop={setChangeNumPop} setThankPop={setThankPop} expTime={expTime} setExpTime={setExpTime} OTPValidTime={OTPValidTime} />
        <ChangeNumPop changeNumPop={changeNumPop} setChangeNumPop={setChangeNumPop} num={num} setNum={setNum} setOtpPop={setOtpPop} setExpTime={setExpTime} OTPValidTime={OTPValidTime} />
        <ThankPop thankPop={thankPop} setThankPop={setThankPop} num={num} />

        <Box className="heroSection">
          <img src={homeBlurBg} className='homeBourBg' />
          <img src={homeBgImgMobile} className='bgImg HomeBgMobile' />
          <img src={homeBgImgTabe} className='bgImg HomeBgTabe' />
          <img src={homeBgImg} className='bgImg HomeBg' />
          <img src={homeGrayBg} className='homeGrayBg' />
          <Box className="homeTopBtnBox">

            <Box onClick={() => {
              setHomeBtm(0)
              handelChangeLanguage("English")
            }} className={homeBtn === 0 ? "appBtn" : "whiteBtn"} >
              <Typography>English</Typography>
            </Box>

            <Box onClick={() => {
              setHomeBtm(1)
              handelChangeLanguage("Hindi")
            }} className={homeBtn === 1 ? "appBtn" : "whiteBtn"} >
              <Typography>Hindi</Typography>
            </Box>


          </Box>
          <Typography className='homeHeader'>{t("home.homeHeader")}</Typography>
          <Typography className='homeSubText'>{t("home.homeSubHeader")}</Typography>
          <Box className="homeTikBox">

            <Box className="tikTextBox">
              <img src={tikBox} />
              <Typography>{t("home.LakhTrekkers")}</Typography>
            </Box>
            <Box className="tikTextBox">
              <img src={tikBox} />
              <Typography>{t("home.ZeroCost")}</Typography>
            </Box>
            <Box className="tikTextBox">
              <img src={tikBox} />
              <Typography>{t("home.SeJoin")}</Typography>
            </Box>
          </Box>
          <Typography className='homeSub2Text'>{t("home.BecomePartner")}</Typography>

          <Box className="homeContactBox">
            <Box className="navItem">
              <Box className="NIconBox">
                <img src={callIcom} />
              </Box>
              <Box>
                <Typography>{t("navText.callUs")}</Typography>
                <Typography>99999999</Typography>
              </Box>
            </Box>

            <Box className="navItem">
              <Box className="NIconBox">
                <img src={whatsapIcom} />
              </Box>
              <Box>
                <Typography>{t("navText.WhatsApp")}</Typography>
                <Typography>99999999</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="hero2ndSection">
          <img src={redBlur} className='redBlur1' />
          <img src={redBlur} className='redBlur2' />
          <img src={begPackBg} className='bagPackBg' />
          <img src={locationTrak1} className='locationTrackBg' />
          <img src={locationTrak2} className='locationTrackBg2' />

          <Box className="h2Header">
            <Typography>Why</Typography>
            <img src={cl2Logo} />
          </Box>
          <Typography className='hero2SubHeader'>{t("home.WeUcustomers")}</Typography>

          <Box className="BookingCardBox">
            <Box className="bookingCard">
              <img src={bCard1} className='bookingCardI1mg' />
              <Typography>{t("home.BookingMEasy")}</Typography>
              <span>{t("home.TrekkersCan")}</span>
            </Box>
            <Box className="bookingCard">
              <img src={bCard2} className='bookingCardI2mg' />
              <Typography>{t("home.FullAssurance")}</Typography>
              <span>{t("home.NoMoreWorries")}</span>
            </Box>
            <Box className="bookingCard">
              <img src={bCard3} className='bookingCardI3mg' />
              <Typography>{t("home.NationwideSpotlight")}</Typography>
              <span>{t("home.YouGnationwide")}</span>
            </Box>
          </Box>


          <Box className="graphSection">
            <img src={mounten1Bg} className='mountenBg1' />
            <img src={tentBg} className='tentBg' />
            <img src={redBlur} className='redBlur' />
            <img src={locationTrak1} className='locationTraker' />


            <Typography className='headerGrph'><span>{t("home.How")}</span> {t("home.ItWorks")}</Typography>

            <Box className="graphBox">
              <Box className="GrowBox">
                <Box className="gselBox leftBorder rightPadding">
                  <Box className="topDot"></Box>
                  <img src={graphImg1} className='graphImg1' />
                  <img src={Rline} className='rLine' />
                </Box>

                <Box className="gselBox leftPadding">
                  <Box className="grLabelBox">
                    <img src={num1} />
                    <Typography>{t("home.CallMUs")}</Typography>
                  </Box>
                  <Typography className='grSubLabel'>{t("home.MessageNumber")}</Typography>
                </Box>

              </Box>

              <Box className="GrowBox">
                <Box className="gselBox leftBorderGray rightPadding">
                  <Box className="grLabelBox">
                    <img src={num2} />
                    <Typography>{t("home.AddUBusiness")}</Typography>
                  </Box>
                  <Typography className='grSubLabel'>{t("home.AnExecutive")}</Typography>
                </Box>
                <Box className="gselBox leftPadding">
                  <img src={graphImg2} className='graphImg2' />
                  <img src={Wline} className='wline' />
                </Box>
              </Box>

              <Box className="GrowBox">
                <Box className="gselBox leftBorderGray rightPadding">
                  <Box className="bottomDot"></Box>
                  <img src={graphImg3} className='graphImg3' />
                  <img src={Wline} className='rLine' />
                </Box>

                <Box className="gselBox leftPadding">
                  <Box className="grLabelBox">
                    <img src={num3} />
                    <Typography>{t("home.Publish")}</Typography>
                  </Box>
                  <Typography className='grSubLabel'>{t("home.YouData")}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Typography className='headerGrph hegpmb'>{t("home.Entry")}<span> {t("home.Requirements")}</span></Typography>
          <Box className="entryBox">
            <img src={mounten2Bg} className='mounten2bg' />
            <img src={redBlur} className='redBg' />
            <img src={mounten3Bg} className='mounten3bg' />
            <img src={redBlur} className='redBg2' />

            <Box className="entryRowBox">
              <img src={experienceIcon} />
              <Typography>{t("home.YouShouldHave")} <span>{t("home.Experience")}</span> {t("home.inConductingTreks")}</Typography>
            </Box>

            <Box className="entryRowBox enBorder">
              <Typography>{t("home.A")} <span>{t("home.QualifiedTeam")}</span> {t("home.whoCguide")}</Typography>
              <img src={qualifiedIcon} />
            </Box>

            <Box className="entryRowBox">
              <img src={trekingIcon} />
              <Typography>{t("home.AdequateAof")} <span>{t("home.TrekkingGear")}</span> {t("home.includingSleepingTents")}</Typography>
            </Box>
          </Box>
        </Box>
        <Footer otpPop={otpPop} setOtpPop={setOtpPop} num={num} setNum={setNum} />
      </Box>
    </>
  )
}
