import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//pages
import Home from "./Pages/Home";
import TermsConditionPage from "./Pages/TermsCondition";
import PrivacyPolicyPage from "./Pages/PrivacyPolicy";
import CancellationPolicyPage from "./Pages/CancellationPolicy";

export default function App() {
  const [langDropVal, setLanDropVal] = useState("English");

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Home langDropVal={langDropVal} setLanDropVal={setLanDropVal} />
          }
        />

        <Route
          path="Terms"
          element={
            <TermsConditionPage
              langDropVal={langDropVal}
              setLanDropVal={setLanDropVal}
            />
          }
        />
        <Route
          path="Policy"
          element={
            <PrivacyPolicyPage
              langDropVal={langDropVal}
              setLanDropVal={setLanDropVal}
            />
          }
        />
        <Route
          path="Cancellation"
          element={
            <CancellationPolicyPage
              langDropVal={langDropVal}
              setLanDropVal={setLanDropVal}
            />
          }
        />
      </Routes>
    </>
  );
}
