import axios from "axios";

export const sendOTP = async ({ num }) => {
  const OTP = Math.floor(1000 + Math.random() * 9000);
  localStorage.setItem("varValue", OTP);
  localStorage.setItem("varNumber", num);

  const url = "https://www.fast2sms.com/dev/bulkV2";
  // const url = "";
  if (num) {
    const params = {
      authorization:
        "CKzTeBgJLQYVXXWhm9cJbLBqqIAC9bdMWu0KZxamwett6uOqaS0PGh1RSKmf",
      route: "dlt",
      sender_id: "BMTREK",
      message: "170510",
      variables_values: OTP,
      flash: "0",
      numbers: num,
    };
    try {
      const response = await axios.get(url, { params });
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }
};
