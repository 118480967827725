import emailjs from "emailjs-com";

export const sendEmail = (num) => {
  const templateParams = {
    message: `You got a new visiter with this number: ${num}`,
  };

  emailjs
    .send(
      "service_q65riuc",
      "template_spo1q6d",
      templateParams,
      "ZRKEcORY8g_ss_cvE"
    )
    .then(
      (result) => {
        console.log("Email successfully sent!", result.text);
      },
      (error) => {
        console.log("Failed to send email.", error.text);
      }
    );
};
