import React from 'react';
import "./style.css"
import { useNavigate } from 'react-router-dom';




//components
import { Box, Typography } from "@mui/material";
import NavBar from '../../Components/NavBar';
import PrivacyPolicy from "../../Components/PrivacyPolicy";
import Footer from "../../Components/Footer";


export default function PrivacyPolicyPage({ langDropVal, setLanDropVal, }) {
  const navigate = useNavigate();



  return (
    <>
      <Box className="policyContainer">
        <NavBar langDropVal={langDropVal} setLanDropVal={setLanDropVal} />

        <Box className="policyNav">
          <Typography className="policyNavText"
            onClick={() => navigate("/Terms")}>
            Terms Condition
          </Typography>
          <Typography className="policyNavText policyNavActiveText"
            onClick={() => navigate("/Policy")}
          >
            Privacy policy
          </Typography>
          <Typography className="policyNavText"
            onClick={() => navigate("/Cancellation")}
          >
            Cancellation policy
          </Typography>
        </Box>
        <PrivacyPolicy />

        <Footer />
      </Box>
    </>
  )
}
